.team-page-modal {
  display: flex !important;
  align-items: center;
  .modal-dialog {
    scroll-behavior: smooth;
    max-width: 610px;
    width: 100%;
    margin: auto;
    padding: 20px 0px 20px 0px;
  }
}

.ant-switch-inner {
  font-size: 10px !important;
  margin: -2px 7px 0 22px !important;
}

.ant-switch-checked .ant-switch-inner {
  margin: -2px 22px 0 7px !important;
}
.ant-switch-checked {
  border-radius: 10px !important;
}

input {
  box-shadow: none !important;
  border: 1px solid #231b381c !important;
}
select {
  box-shadow: none !important;
  border: 1px solid #231b381c !important;
}

textarea {
  box-shadow: none !important;
  border: 1px solid #231b381c !important;
}

select:focus {
  border: 1px solid #231b3842 !important;
}
select:active {
  border: 1px solid #231b3842 !important;
}

input:focus {
  border: 1px solid #231b3842 !important;
}
input:active {
  border: 1px solid #231b3842 !important;
}

textarea:focus {
  border: 1px solid #231b3842 !important;
}
textarea:active {
  border: 1px solid #231b3842 !important;
}


.t-a-access-section{
  display: grid;
  .t-a-access-card{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(238, 238, 238);
    .t-a-access-item-title{
      font-size: 14px;
    }
    .t-a-access-item{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      label{
        span{
          padding: 5px;
          font-size: 14px;
        }
      }
    }
  }
}

.team-switch-button {
  .ant-switch-handle{
    width: 16px !important;
    height: 13px !important;
  }
}

.team-switch-button {
  min-width: 40px !important;
  height: 18px !important;
  line-height: 18px !important;
}