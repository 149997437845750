.css-1u9des2-indicatorSeparator{
  display: none !important;
}

.modal-content{
  border-radius: 10px !important;
  box-shadow: 0 2px 8px rgba(0,0,0,.15);
  border: none !important;
}
.modal-body{
  border-radius: 10px !important;
  box-shadow: 0 2px 8px rgba(0,0,0,.15);
}

Button.MuiButton-contained{
  background-color: #222d45 !important;
  color: #eee !important;
  text-transform: capitalize !important;
  height: 35px !important;
  font-family: "Poppins", sans-serif !important;
  width: auto !important;
  font-weight: 400 !important;
}

Button.MuiMenuButton-variantOutlined{
  background-color: #fff0 !important;
  color: #222d45 !important;
  text-transform: capitalize !important;
  height: 30px !important;
  font-family: "Poppins", sans-serif !important;
  width: auto !important;
  font-weight: 400 !important;
}